const BLUE_MINT = {
    primary: '#A3E8FF', //ADD8E6
    secondary: '#608192',
    bg: '#23343A',
    fg: '#23343B',
    dark: '#171718',
    text1: '#EDFFF5',
    text2: '#e4eaf7'
}

export const SCHEME = BLUE_MINT;